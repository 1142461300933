<template>
  <div class="grid lg:grid-cols-2 grid-cols-1 gap-4">
    <p class="text-black font-medium md:text-[20px] text-[15px]">{{ $t('Shipping Fees') }}</p>
    <div class="flex gap-4 items-center text-primary justify-end whitespace-nowrap">
      <p v-if="orderPrice.total" class="font-medium text-xl border-0 border-r border-solid border-grey pr-4">{{ orderPrice.total ? Number(orderPrice.total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00' }}{{ $t(' EGP') }}</p>
      <span v-if="serviceLevels.length > 0" class="border border-solid border-primary rounded-full px-3 py-2">{{ $t('Service Level:') }}  {{ serviceLevels[0].name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['serviceLevels', 'orderPrice']
}
</script>